<template>
	<TooltipMenu v-model="show">
		<template #anchor="{ show }">
			<slot v-bind="{ show }" />
		</template>
		<template #content>
			<TooltipButton @click="openSecurity">
				<template #text>
					{{ t('UserDropdown.security') }}
				</template>
				<template #icon>
					<LockIcon />
				</template>
			</TooltipButton>
			<TooltipButton @click="toggleTheme">
				<template #text>
					{{ t('UserDropdown.theme') }}
				</template>
				<template #icon>
					<Component :is="selectedThemeIcon" />
				</template>
			</TooltipButton>
			<TooltipButton @click="logoutAndClose">
				<template #text>
					{{ t('UserDropdown.logout') }}
				</template>
				<template #icon>
					<LogoutIcon />
				</template>
			</TooltipButton>
		</template>
	</TooltipMenu>
</template>
<script setup lang="ts">
import LogoutIcon from '../assets/icons/logout.svg'
import LockIcon from '../assets/icons/lock.svg'
import { useUserStore } from '../stores/user'
import { useI18n } from 'vue-i18n'
import TooltipButton from './TooltipButton.vue'
import TooltipMenu from './TooltipMenu.vue'
import { computed, ref } from 'vue'
import { useLayoutStore } from '../stores/layout'
import ThemeDarkIcon from '../assets/icons/theme-dark.svg'
import ThemeLightIcon from '../assets/icons/theme-light.svg'
import { useModalStore } from '../stores/modal'

const { t } = useI18n()

const show = ref(false)

const { logout } = useUserStore()

const logoutAndClose = async () => {
	show.value = false
	await logout()
}

type Theme = {
	id: 'light' | 'dark'
	name: string
	icon: string
}

const layout = useLayoutStore()

const themes = computed<Theme[]>(() => [
	{
		id: 'dark',
		name: t('TheNavbarBottomMobile.dark'),
		icon: ThemeDarkIcon
	},
	{
		id: 'light',
		name: t('TheNavbarBottomMobile.light'),
		icon: ThemeLightIcon
	}
])

const selectedThemeIcon = computed(
	() => themes.value.find((theme) => theme.id === layout.theme)?.icon
)

const toggleTheme = () => {
	layout.toggleTheme()
}

const modal = useModalStore()

const openSecurity = () => modal.open('AccountSecurity')
</script>

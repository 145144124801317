<template>
	<Component
		:is="as"
		type="button"
		class="group relative inline-flex min-w-0 max-w-full select-none items-center justify-center enabled:focus-visible:outline enabled:focus-visible:outline-1 enabled:focus-visible:outline-offset-0 enabled:focus-visible:outline-pink enabled:focus-visible:ring-4 enabled:focus-visible:ring-pink/20 dark:!ring-0"
		:class="{
			'w-9': size === 'sm' && iconOnly,
			'w-10.5': size === 'md' && iconOnly,
			'w-11.5': size === 'lg' && iconOnly,
			'px-2': size === 'sm' && !iconOnly,
			'px-4': size === 'md' && !iconOnly,
			'px-5': size === 'lg' && !iconOnly,
			'gap-1.5 rounded-2lg': size === 'sm',
			'gap-2 rounded-xl': size !== 'sm',
			'h-9': size === 'sm',
			'h-10.5': size === 'md',
			'h-11.5': size === 'lg',
			'cursor-pointer': !disabled,
			'cursor-not-allowed bg-fill-tertiary outline outline-1 -outline-offset-1 outline-separator-secondary':
				disabled,
			'shadow shadow-gray/2 hover:shadow-gray/6 active:shadow-gray/6 dark:bg-fill-primary dark:outline-fill-tertiary':
				color === 'none' && !disabled && !flat,
			'bg-fill-primary outline outline-1 -outline-offset-1 outline-separator-primary hover:!outline-separator-secondary active:bg-fill-secondary active:outline-separator-secondary dark:hover:bg-fill-tertiary dark:active:bg-fill-quaternary':
				color === 'none' && !disabled,
			'overflow-hidden': color !== 'none' && !disabled,
			'dark:bg-fill-tertiary dark:outline-fill-tertiary':
				color === 'none' && !disabled && flat,
			'bg-pink': color === 'pink' && !disabled,
			'bg-cyan': color === 'blue' && !disabled,
			'bg-[--custom-color]':
				!['none', 'blue', 'pink'].includes(color) && !disabled
		}"
		:style="{
			'--custom-color': !['none', 'blue', 'pink'].includes(color)
				? color
				: undefined
		}"
		@click="click"
		:disabled="disabled"
	>
		<ButtonHover v-if="color !== 'none' && !disabled" />
		<div
			class="h-5 w-5 empty:hidden"
			:class="{
				'text-label-tertiary': disabled,
				'text-label-secondary group-hover:text-label-primary group-active:text-label-primary':
					color === 'none' && !disabled && !textColor,
				'text-white': color !== 'none' && !disabled && !textColor,
				'text-lava': textColor === 'lava' && !disabled,
				'relative z-10': color !== 'none' && !disabled
			}"
		>
			<slot name="leftIcon" :disabled="disabled" />
		</div>
		<div
			class="peer absolute bottom-0 right-3 top-0 flex items-center empty:hidden"
			:class="{
				'text-label-tertiary': disabled,
				'text-label-primary': color === 'none' && !disabled && !textColor,
				'text-lava': textColor === 'lava' && !disabled,
				'text-white': color !== 'none' && !disabled && !textColor,
				'font-medium': color === 'none' || disabled,
				'font-semibold': color !== 'none' && !disabled,
				'z-10': color !== 'none' && !disabled
			}"
		>
			<slot name="rightStick" :disabled="disabled" />
		</div>
		<div
			class="sticky right-14 max-w-full truncate text-sm empty:hidden peer-empty:relative peer-empty:right-auto"
			:class="{
				'text-label-tertiary': disabled,
				'text-label-primary': color === 'none' && !disabled && !textColor,
				'text-lava': textColor === 'lava' && !disabled,
				'text-white': color !== 'none' && !disabled && !textColor,
				'font-medium': color === 'none',
				'font-semibold': color !== 'none',
				'relative z-10': color !== 'none' && !disabled
			}"
		>
			<slot />
		</div>
		<div
			class="h-5 w-5 empty:hidden"
			:class="{
				'text-label-tertiary': disabled,
				'text-label-primary dark:text-label-secondary dark:group-hover:text-label-primary dark:group-active:text-label-primary':
					color === 'none' && !disabled && !textColor,
				'text-white': color !== 'none' && !disabled && !textColor,
				'text-lava': textColor === 'lava' && !disabled,
				'relative z-10': color !== 'none' && !disabled
			}"
		>
			<slot name="rightIcon" :disabled="disabled" />
		</div>
	</Component>
</template>
<script setup lang="ts">
import type { Component } from 'vue'
import ButtonHover from './ButtonHover.vue'

const props = withDefaults(
	defineProps<{
		size?: 'sm' | 'md' | 'lg'
		color?: 'none' | 'pink' | 'blue' | string
		textColor?: 'lava'
		disabled?: boolean
		iconOnly?: boolean
		as?: string | Component
		flat?: boolean
	}>(),
	{
		size: 'md',
		color: 'none',
		disabled: false,
		iconOnly: false,
		as: 'button'
	}
)

const emit = defineEmits<{
	(e: 'click'): void
}>()

const click = () => {
	if (props.disabled) {
		return
	}

	emit('click')
}
</script>

<template>
	<div class="fixed bottom-0 left-0 right-0 top-0 flex flex-col">
		<AlertGroup class="fixed left-0 right-0 top-[19px]" />
		<TheNavbar class="flex-shrink-0 self-stretch" :border="border" />
		<div
			class="flex flex-1 flex-col items-center gap-8 overflow-y-scroll p-8"
			@scroll="scroll"
			id="view"
		>
			<div class="w-full max-w-[1208px] flex-1">
				<slot />
			</div>
			<TheFooter class="mt-auto" />
		</div>
	</div>
</template>

<script setup lang="ts">
import TheNavbar from '../components/TheNavbar.vue'
import TheFooter from '../components/TheFooter.vue'
import { ref } from 'vue'
import AlertGroup from '../components/AlertGroup.vue'

const border = ref(false)

const scroll = (event: Event) => {
	if (!event || !event.target) {
		return
	}

	const scrollTop = (event.target as HTMLElement).scrollTop

	if (scrollTop) {
		border.value = true
	} else {
		border.value = false
	}
}
</script>

<template>
	<div
		class="flex flex-col items-center gap-2.5 px-4 pb-4 text-center text-xs tracking-wide"
	>
		<div
			class="flex flex-wrap justify-center gap-x-5 gap-y-2.5 text-label-secondary"
		>
			<FooterLink href="/tos.pdf" target="_blank">
				{{ t('TheFooter.tos') }}
			</FooterLink>
			<FooterLink href="/privacy.pdf" target="_blank">
				{{ t('TheFooter.privacy') }}
			</FooterLink>
			<FooterLink href="/gdpr.pdf" target="_blank">
				{{ t('TheFooter.privacyNotice') }}
			</FooterLink>
			<FooterLink to="/api">
				{{ t('TheFooter.api') }}
			</FooterLink>
			<FooterLink :href="botLink" target="_blank">
				{{ t('TheFooter.telegramBot') }}
			</FooterLink>
			<div>© Nubee {{ year }}. {{ t('TheFooter.rights') }}</div>
		</div>
		<div class="text-label-tertiary">
			Neptunia RT LTD / Achaion, 35, 5th floor, Flat/Office 17, Agios Andreas,
			1101, Nicosia, Cyprus
		</div>
	</div>
</template>
<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import FooterLink from './FooterLink.vue'

const { t } = useI18n()

const year = new Date().getFullYear()

const botLink = `tg://resolve?domain=NubeeaiApp2_bot`
</script>

<template>
	<Component
		:is="as ?? 'RouterLink'"
		:type="as === 'button' ? 'button' : undefined"
		@click="click()"
		class="z-20 flex min-w-0 flex-1 cursor-pointer flex-col items-center justify-center gap-1 py-2.5 font-medium text-label-secondary hover:text-label-primary focus:text-pink focus:outline-none active:scale-95 active:transform"
		:class="{ '!font-semibold !text-pink': active }"
		:tabindex="active ? '-1' : '0'"
	>
		<Component :is="icon" class="h-5 w-5" />
		<div
			class="max-w-full truncate text-center text-[11px] leading-[14px] tracking-widest"
		>
			<slot />
		</div>
	</Component>
</template>
<script setup lang="ts">
defineProps<{
	as?: 'RouterLink' | 'button' | 'a'
	icon: any
	active?: boolean
}>()

const emit = defineEmits<{
	(e: 'click'): void
}>()

const click = () => emit('click')
</script>

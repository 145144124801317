<template>
	<DotLottieVue :src="src" autoplay loop class="h-14 w-14" />
</template>
<script setup lang="ts">
import { DotLottieVue, setWasmUrl } from '@lottiefiles/dotlottie-vue'
import wasmUrl from '@lottiefiles/dotlottie-web/dist/dotlottie-player.wasm?url'

setWasmUrl(wasmUrl)

defineProps<{
	src: string
}>()
</script>

<template>
	<BaseButton
		size="sm"
		color="none"
		class="!absolute right-4 top-4 !rounded-full"
		@click="onClose"
	>
		<template #leftIcon>
			<Close />
		</template>
	</BaseButton>
</template>
<script setup lang="ts">
import BaseButton from '../BaseButton.vue'
import Close from '../../assets/icons/close.svg'
import { useModalStore } from '../../stores/modal'

const { close } = useModalStore()

const emit = defineEmits<{
	(e: 'close'): void
}>()

const onClose = () => {
	close()
	emit('close')
}
</script>

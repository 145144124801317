<template>
	<TooltipHover v-model="show">
		<template #anchor="{ show }">
			<slot name="anchor" v-bind="{ show }" />
		</template>
		<template #tooltip>
			<div class="w-56 p-1">
				<slot name="content" />
			</div>
		</template>
	</TooltipHover>
</template>
<script setup lang="ts">
import TooltipHover from './TooltipHover.vue'

const show = defineModel<boolean>({ default: false })
</script>

<template>
	<div class="flex w-full flex-col items-center pt-3 sm:w-[458px]">
		<ModalClose v-if="closable" @close="onClose" />
		<TransitionGroup
			enter-active-class="transform transition-all"
			leave-active-class="transform transition-all"
			leave-to-class="scale-0 opacity-0"
			enter-from-class="scale-0 opacity-0"
		>
			<slot name="icon" />
		</TransitionGroup>
		<DialogTitle
			class="mt-4 text-center text-[22px] font-semibold leading-[26px] tracking-tightestly text-label-primary"
		>
			{{ title }}
		</DialogTitle>
		<p
			class="mt-2.5 text-center text-sm text-label-primary *-[a]:font-semibold *-[a]:text-pink *-[a]:no-underline empty:hidden"
			v-html="text"
		/>
		<div
			class="mt-6 flex w-full justify-center gap-2.5"
			:class="{ 'flex-col': bottomCol }"
		>
			<slot name="bottom" />
		</div>
	</div>
</template>
<script lang="ts" setup>
import { DialogTitle } from '@headlessui/vue'
import ModalClose from './ModalClose.vue'
import { useModalStore } from '../../stores/modal'
import { watch } from 'vue'

const { setClosable, setBlur } = useModalStore()

const props = defineProps<{
	title: string
	text?: string
	closable?: boolean
	bottomCol?: boolean
	blur?: boolean
}>()

const emit = defineEmits<{
	(e: 'close'): void
}>()

watch(
	() => props.blur,
	() => setBlur(!!props.blur),
	{ immediate: true }
)

watch(
	() => props.closable,
	() => setClosable(!!props.closable),
	{ immediate: true }
)

const onClose = () => emit('close')
</script>

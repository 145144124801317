import {
	ApolloClient,
	createHttpLink,
	InMemoryCache,
	split,
	from
} from '@apollo/client/core'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { getMainDefinition } from '@apollo/client/utilities'
import { createClient } from 'graphql-ws'

// WS connection to the API
const wsLink = new GraphQLWsLink(
	createClient({
		url: 'wss://ws.nubee.ai/graphql'
	})
)

// HTTP connection to the API
const httpLink = createHttpLink({
	// You should use an absolute URL here
	uri: 'https://api.nubee.ai/graphql',
	credentials: 'include'
})

// choose wsLink or httpLink based on operation
const splitLink = split(
	// split based on operation type
	({ query }) => {
		const definition = getMainDefinition(query)
		return (
			definition.kind === 'OperationDefinition' &&
			definition.operation === 'subscription'
		)
	},
	wsLink,
	httpLink
)

// Cache implementation
const cache = new InMemoryCache()

const link = from([splitLink])

// Create the apollo client
export const apolloClient = new ApolloClient({
	link,
	cache,
	defaultOptions: {
		watchQuery: {
			fetchPolicy: 'no-cache',
			errorPolicy: 'ignore'
		},
		query: {
			fetchPolicy: 'no-cache',
			errorPolicy: 'all'
		}
	}
})

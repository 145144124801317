<template>
	<BaseButton size="md" color="none" @click="toggleTheme" iconOnly>
		<template #leftIcon>
			<Component :is="selectedThemeIcon" />
		</template>
	</BaseButton>
</template>
<script setup lang="ts">
import ThemeDarkIcon from '../assets/icons/theme-dark.svg'
import ThemeLightIcon from '../assets/icons/theme-light.svg'
import BaseButton from './BaseButton.vue'
import { useLayoutStore } from '../stores/layout'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const layout = useLayoutStore()

const themes = computed(() => [
	{
		id: 'dark',
		name: t('TheNavbarBottomMobile.dark'),
		icon: ThemeDarkIcon
	},
	{
		id: 'light',
		name: t('TheNavbarBottomMobile.light'),
		icon: ThemeLightIcon
	}
])

const selectedThemeIcon = computed(
	() => themes.value.find((theme) => theme.id === layout.theme)?.icon
)

const toggleTheme = () => {
	layout.toggleTheme()
}
</script>

import { defineAsyncComponent } from 'vue'
import SignIn from './SignIn.vue'
import SignUp from './SignUp.vue'
import AgeVerification from './AgeVerification.vue'

const modals = {
	PasswordReset: defineAsyncComponent(() => import('./PasswordReset.vue')),
	AccountSecurity: defineAsyncComponent(() => import('./AccountSecurity.vue')),
	SignIn,
	SignUp,
	TopUp: defineAsyncComponent(() => import('./TopUp.vue')),
	VerifyEmail: defineAsyncComponent(() => import('./VerifyEmail.vue')),
	AgeVerification,
	TermsOfService: defineAsyncComponent(() => import('./TermsOfService.vue')),
	PaymentStatus: defineAsyncComponent(() => import('./PaymentStatus.vue')),
	PaymentSuccess: defineAsyncComponent(() => import('./PaymentSuccess.vue')),
	PaymentFail: defineAsyncComponent(() => import('./PaymentFail.vue')),
	AccountBanned: defineAsyncComponent(() => import('./AccountBanned.vue')),
	APIRequest: defineAsyncComponent(() => import('./APIRequest.vue'))
}

export default modals

<template>
	<div class="mb-6 flex gap-4">
		<Component
			class="cursor-pointer select-none text-[20px] font-semibold leading-[24px] tracking-[0.0225em]"
			:class="{
				'text-pink': activeIndex === index,
				'text-label-secondary hover:text-label-primary active:scale-95 active:transform':
					activeIndex !== index
			}"
			v-for="(tab, index) in tabs"
			@click="click(index)"
			:is="activeIndex === index ? DialogTitle : 'h2'"
		>
			{{ tab }}
		</Component>
	</div>
</template>
<script setup lang="ts">
import { DialogTitle } from '@headlessui/vue'

defineProps<{
	activeIndex?: number
	tabs: string[]
}>()

const emit = defineEmits<{
	(e: 'click', index: number): void
}>()

const click = (index: number) => emit('click', index)
</script>

<template>
	<div
		class="m-0 inline-flex cursor-default focus:outline-none"
		@mouseenter="showTooltip"
		tabindex="0"
		ref="anchor"
	>
		<slot name="anchor" v-bind="{ show }" />
		<Teleport to="body">
			<div
				class="group/tooltip fixed cursor-pointer focus:outline-none"
				:style="{ height, width, left, top }"
				v-if="show"
				@mouseleave="hideTooltip"
				ref="tooltipContainer"
				tabindex="-1"
			>
				<BaseCard
					class="invisible !absolute rounded-xl !shadow-gray/6 group-hover/tooltip:visible group-focus/tooltip:visible"
					:class="{
						'left-0': x === 'left',
						'left-1/2 -translate-x-1/2 transform': x === 'center',
						'right-0': x === 'right',
						'bottom-full mb-2 after:absolute after:-bottom-2 after:left-0 after:right-0 after:h-2 after:w-full':
							y === 'top',
						'top-full mt-2 before:absolute before:-top-2 before:left-0 before:right-0 before:h-2 before:w-full':
							y === 'bottom'
					}"
					ref="tooltipRef"
				>
					<slot name="tooltip" />
				</BaseCard>
			</div>
		</Teleport>
	</div>
</template>

<script setup lang="ts">
import { ref, nextTick } from 'vue'
import BaseCard from './BaseCard.vue'

const props = defineProps<{
	borderElementId?: string
}>()

const x = ref<'left' | 'center' | 'right'>('center')
const y = ref<'top' | 'bottom'>('bottom')

const anchor = ref<HTMLElement>()
const tooltipContainer = ref<HTMLElement>()
const tooltipRef = ref<typeof BaseCard>()

const show = defineModel<boolean>({ default: false })

const showTooltip = async () => {
	show.value = true

	await nextTick()

	setTooltipContainerPos()
	calcBestPosition()
}
const hideTooltip = () => {
	show.value = false
}

const height = ref('0px')
const width = ref('0px')
const top = ref('0px')
const left = ref('0px')

const setTooltipContainerPos = () => {
	if (!anchor.value) {
		return hideTooltip()
	}

	tooltipContainer.value?.focus()

	const anchorRect = anchor.value.getBoundingClientRect()

	height.value = `${anchorRect.height}px`
	width.value = `${anchorRect.width}px`

	top.value = `${anchorRect.top + window.scrollY}px`
	left.value = `${anchorRect.left + window.scrollX}px`
}

const calcBestPosition = async () => {
	x.value = 'center'

	await nextTick()

	if (tooltipRef.value) {
		const tooltipRect = tooltipRef.value.$el.getBoundingClientRect()
		const borderElement = props.borderElementId
			? document.getElementById(props.borderElementId)
			: undefined
		let borderRect = {
			left: 0,
			top: 0
		}

		if (borderElement) {
			borderRect = borderElement.getBoundingClientRect()
		}

		const screenWidth = borderElement
			? borderElement.clientWidth
			: window.innerWidth
		const screenHeight = borderElement
			? borderElement.clientHeight
			: window.innerHeight

		// Calculate X-axis position
		const overflowX = {
			left: tooltipRect.left - borderRect.left < 0,
			right: tooltipRect.right - borderRect.left > screenWidth
		}

		if (overflowX.left && !overflowX.right) {
			x.value = 'left'
		} else if (overflowX.right && !overflowX.left) {
			x.value = 'right'
		}

		// Calculate Y-axis position
		if (tooltipRect.bottom - borderRect.top > screenHeight) {
			y.value = 'top'
		} else if (tooltipRect.top - borderRect.top < 0) {
			y.value = 'bottom'
		}
	}
}
</script>

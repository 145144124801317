import { useI18n } from 'vue-i18n'
import { useAlertStore } from '../stores/alert'
import { ApolloError } from '@apollo/client/errors'
import { useModalStore } from '../stores/modal'
import { useUserStore } from '../stores/user'

export const useErrorCatcher = () => {
	const { t } = useI18n()
	const alert = useAlertStore()
	const modal = useModalStore()
	const user = useUserStore()

	const codeHandler = (code: string) => {
		if (code === 'UNAUTHORIZED') {
			user.logout()
			modal.open('SignIn')
			return true
		}
		if (code === 'NO_CREDITS_LEFT') {
			modal.open('TopUp')
			return true
		}
		if (code === 'TERMS_NOT_ACCEPTED') {
			user.setAcceptedTerms(false)
			modal.open('TermsOfService')
			return true
		}
		if (code === 'EMAIL_NOT_VERIFIED') {
			user.setEmailVerified(false)
			modal.open('VerifyEmail')
			return true
		}
		if (code === 'USER_IS_BANNED') {
			modal.open('AccountBanned')
			return true
		}

		return false
	}

	const handleGraphQLErrors = (errors: ApolloError['graphQLErrors']) => {
		let handled = 0

		errors.forEach((error) => {
			if (!error.extensions?.code) {
				return
			}

			const { code } = error.extensions

			if (codeHandler(code as string)) {
				handled++
				return
			}

			const translated = t(`errors.${code}`)

			if (translated === `errors.${code}`) {
				handled++
				alert.push(error.message, 'red')
			} else {
				handled++
				alert.push(translated, 'red')
			}
		})

		return handled === errors.length
	}

	const handleError = (error: Error) => {
		alert.push(error.message, 'red')

		return true
	}

	const handle = (error: ApolloError | Error) => {
		if (error instanceof ApolloError) {
			return handleGraphQLErrors(error.graphQLErrors)
		}
		if (error instanceof Error) {
			return handleError(error)
		}

		return false
	}

	return { handle }
}

export const hasErrorCode = (err: ApolloError, code: string) => {
	if (err.graphQLErrors) {
		return err.graphQLErrors.some((error) => error.extensions?.code === code)
	}

	return false
}

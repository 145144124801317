<template>
	<Component
		:is="as"
		:to="to"
		class="cursor-pointer no-underline hover:text-label-primary focus:text-label-primary focus:outline-none active:scale-95 active:transform"
	>
		<slot />
	</Component>
</template>
<script setup lang="ts">
import { computed } from 'vue'

const props = defineProps<{
	to?: string
}>()

const as = computed(() => (props.to ? 'RouterLink' : 'a'))
</script>

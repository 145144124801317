import { createApp, h, provide } from 'vue'
import { createPinia } from 'pinia'
import './style.css'
import App from './App.vue'
import { router } from './views'
import { DefaultApolloClient } from '@vue/apollo-composable'
import { apolloClient } from './utils/apollo'
import { i18n } from './composables/useLocale'

const pinia = createPinia()

const app = createApp({
	setup() {
		provide(DefaultApolloClient, apolloClient)
	},

	render: () => h(App)
})

app.use(pinia).use(router).use(i18n).mount('#app')

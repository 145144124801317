<template>
	<RouterView v-slot="{ Component }">
		<Component :is="layoutComponent">
			<Suspense :timeout="0">
				<transition
					enter-active-class="transition-all overflow-hidden"
					leave-active-class="transition-all overflow-hidden"
					enter-from-class="transform translate-x-20 opacity-0"
					leave-to-class="transform -translate-x-20 opacity-0"
					mode="out-in"
				>
					<Component :is="Component" />
				</transition>
				<template #fallback>
					<div class="flex h-full w-full items-center justify-center">
						<LoadingDots />
					</div>
				</template>
			</Suspense>
		</Component>
	</RouterView>
	<ModalManager />
</template>

<script setup lang="ts">
import { computed, onBeforeMount, onMounted, watch } from 'vue'
import Mobile from './layouts/Mobile.vue'
import Desktop from './layouts/Desktop.vue'
import { useLayoutStore } from './stores/layout'
import ModalManager from './components/modals/ModalManager.vue'
import { useModalStore } from './stores/modal'
import { useUserStore } from './stores/user'
import { useAlertStore } from './stores/alert'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import LoadingDots from './components/LoadingDots.vue'

// process topup query params
const modal = useModalStore()

onBeforeMount(() => {
	const url = new URL(location.href)
	const uuid = url.searchParams.get('uuid')
	const s2 = url.searchParams.get('s2')

	if (!localStorage.getItem('ageVerified') && !uuid) {
		modal.open('AgeVerification')
	}

	if (s2 && !document.cookie.includes('cid=')) {
		document.cookie = `cid=${s2}; domain=.nubee.ai; path=/; max-age=34560000; secure; samesite=lax`
	}

	if (window.gaGlobal && window.gaGlobal.vid) {
		document.cookie = `gid=${window.gaGlobal.vid}; domain=.nubee.ai; path=/; max-age=34560000; secure; samesite=lax`
	}
})

const { t } = useI18n()

// process layout changes
const layout = useLayoutStore()

const layoutComponent = computed(() => {
	if (layout.forceLayout) {
		return layout.forceLayout
	}
	if (layout.isMobile) {
		return Mobile
	}

	return Desktop
})

const user = useUserStore()
const alert = useAlertStore()

watch(
	() => user.current,
	(from, to) => {
		if (!from || !to || from.totalCredits >= to.totalCredits) {
			return
		}
		if (modal.currentName !== 'PaymentStatus' && modal.show) {
			return
		}

		const diff = to.totalCredits - from.totalCredits

		alert.push(t('App.credited', [diff]), 'green')
	}
)

// process query params
const router = useRouter()

onMounted(() => {
	const url = new URL(location.href)
	const errorParam = url.searchParams.get('error')
	const rParam = url.searchParams.get('r')
	const paymentSuccess = url.searchParams.has('payment-success')
	const paymentFail = url.searchParams.has('payment-fail')

	if (errorParam) {
		alert.push(errorParam.toString(), 'red')
		url.searchParams.delete('error')
	}

	if (rParam) {
		localStorage.setItem('referralCode', rParam.toString())
		url.searchParams.delete('r')
	}

	if (paymentSuccess) {
		url.searchParams.delete('payment-success')
		modal.open('PaymentSuccess')
	}

	if (paymentFail) {
		url.searchParams.delete('payment-fail')
		modal.open('PaymentFail')
	}

	if (errorParam || rParam || paymentSuccess || paymentFail) {
		router.replace({ query: Object.fromEntries(url.searchParams) })
	}
})

// intercom settings

window.intercomSettings = {
	app_id: 'mabavjtg',
	api_base: 'https://api-iam.intercom.io',
	custom_launcher_selector: '#intercom-mobile',
	alignment: 'right',
	background_color: '#ff67b8',
	action_color: '#ff58ae',
	horizontal_padding: 20,
	vertical_padding: 20
}
</script>

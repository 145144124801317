<template>
	<nav class="relative flex h-[72px] select-none px-2.5">
		<div
			class="absolute -top-px left-0 right-0 h-px bg-separator-primary transition-opacity"
			:class="{
				'opacity-0': !border && !menu,
				'opacity-100': border || menu,
				'z-30': menu
			}"
		/>
		<div
			class="absolute left-0 right-0 top-0 z-10 h-[calc(100vh-100%)] -translate-y-full transform bg-black/40"
			@click="closeMenu"
			v-if="menu"
		/>
		<div
			class="absolute bottom-0 left-0 right-0 z-20 transform rounded-t-2xl bg-fill-primary px-1 pb-20 pt-2"
			v-if="menu"
		>
			<TooltipButton
				class="pointer-events-none !gap-3"
				as="button"
				color="none"
				left
				v-if="user.current"
			>
				<template #text>
					{{ user.current.name }}
				</template>
				<template #icon>
					<UserAvatar
						:username="user.current.name"
						:avatar="user.current.avatar ?? undefined"
						class="h-5 w-5"
					/>
				</template>
			</TooltipButton>
			<TooltipButton
				class="!gap-3"
				as="button"
				color="none"
				@click="openSecurity"
				left
				v-if="user.current"
			>
				<template #text>
					{{ t('UserDropdown.security') }}
				</template>
				<template #icon>
					<LockIcon />
				</template>
			</TooltipButton>
			<TooltipButton
				class="!gap-3"
				as="button"
				color="none"
				@click="toggleTheme"
				left
			>
				<template #text>
					{{ t('UserDropdown.theme') }}
				</template>
				<template #icon>
					<Component :is="selectedThemeIcon" />
				</template>
			</TooltipButton>
			<TooltipButton
				class="!gap-3"
				as="button"
				color="none"
				id="intercom-mobile"
				left
			>
				<template #text>
					{{ t('UserDropdown.support') }}
				</template>
				<template #icon>
					<SupportIcon />
				</template>
			</TooltipButton>
			<TooltipButton
				class="!gap-3"
				as="button"
				color="none"
				@click="logoutAndClose"
				left
				v-if="user.current"
			>
				<template #text>
					{{ t('UserDropdown.logout') }}
				</template>
				<template #icon>
					<LogoutIcon />
				</template>
			</TooltipButton>
		</div>
		<NavbarBottomMobileButton
			v-for="item in mainRoutes"
			:to="!item.as ? item.to : undefined"
			:as="item.as"
			:icon="item.icon"
			:active="item.to === route.path"
			:target="item.as ? '_blank' : undefined"
			:href="item.as ? item.to : undefined"
		>
			{{ item.name }}
		</NavbarBottomMobileButton>
		<NavbarBottomMobileButton
			as="button"
			:icon="MenuIcon"
			:active="menu"
			@click="toggleMenu"
		>
			{{ t('TheNavbarBottomMobile.more') }}
		</NavbarBottomMobileButton>
	</nav>
</template>
<script setup lang="ts">
import UndressIcon from '../assets/icons/undress.svg'
import P2PIcon from '../assets/icons/p2p.svg'
import GalleryIcon from '../assets/icons/gallery.svg'
import MenuIcon from '../assets/icons/menu.svg'
import UserAvatar from './UserAvatar.vue'
import LogoutIcon from '../assets/icons/logout.svg'
import LockIcon from '../assets/icons/lock.svg'
import SupportIcon from '../assets/icons/support.svg'
import ThemeLightIcon from '../assets/icons/theme-light.svg'
import ThemeDarkIcon from '../assets/icons/theme-dark.svg'
import { computed, ref } from 'vue'
import { useLayoutStore } from '../stores/layout'
import { useModalStore } from '../stores/modal'
import { useUserStore } from '../stores/user'
import { useI18n } from 'vue-i18n'
import NavbarBottomMobileButton from './NavbarBottomMobileButton.vue'
import TooltipButton from './TooltipButton.vue'
import { useRoute } from 'vue-router'

const { t } = useI18n()

defineProps<{
	border?: boolean
}>()

const route = useRoute()

const user = useUserStore()

const mainRoutes = computed<
	{
		to: string
		name: string
		icon: string
		as?: 'a'
	}[]
>(() => [
	{
		to: '/undress',
		name: t('TheNavbar.undress'),
		icon: UndressIcon
	},
	{
		to: '/my-gallery',
		name: t('TheNavbar.gallery'),
		icon: GalleryIcon
	},
	{
		to: `https://p2p.nubee.ai/${user.current ? `?uuid=${user.current.uuid}` : ''}`,
		name: t('TheNavbar.p2p'),
		icon: P2PIcon,
		as: 'a'
	}
])

const menu = ref(false)

const toggleMenu = () => {
	menu.value = !menu.value
}
const closeMenu = () => {
	menu.value = false
}

const layout = useLayoutStore()

const themes = computed(() => [
	{
		id: 'dark',
		name: t('TheNavbarBottomMobile.dark'),
		icon: ThemeDarkIcon
	},
	{
		id: 'light',
		name: t('TheNavbarBottomMobile.light'),
		icon: ThemeLightIcon
	}
])

const selectedThemeIcon = computed(
	() => themes.value.find((theme) => theme.id === layout.theme)?.icon
)

const { open } = useModalStore()

const logoutAndClose = async () => {
	closeMenu()
	await user.logout()
}

const toggleTheme = () => {
	layout.toggleTheme()
}

const openSecurity = () => open('AccountSecurity')
</script>

<template>
	<BaseInfoModal
		:title="t('modals.AgeVerification.title')"
		:text="t('modals.AgeVerification.text')"
		bottomCol
		blur
	>
		<template #icon>
			<AnimatedEmoji :src="age" />
		</template>
		<template #bottom>
			<BaseButton size="md" color="pink" @click="yes">
				{{ t('modals.AgeVerification.yes') }}
			</BaseButton>
			<BaseButton size="md" color="none" @click="no">
				{{ t('modals.AgeVerification.no') }}
			</BaseButton>
		</template>
	</BaseInfoModal>
</template>
<script setup lang="ts">
import { useModalStore } from '../../stores/modal'
import age from '../../assets/lottie/age.lottie'
import BaseButton from '../BaseButton.vue'
import { useI18n } from 'vue-i18n'
import BaseInfoModal from './BaseInfoModal.vue'
import AnimatedEmoji from '../AnimatedEmoji.vue'

const { t } = useI18n()

const { close } = useModalStore()

const yes = () => {
	localStorage.setItem('ageVerified', '1')

	close('AgeVerification', true)
}

const no = () => {
	window.location.href = 'https://www.google.com'
}
</script>

<template>
	<div class="fixed bottom-0 left-0 right-0 top-0 flex flex-col">
		<AlertGroup class="fixed left-0 right-0 top-4 px-4" />
		<TheNavbarMobile class="flex-shrink-0 self-stretch" :border="borderTop" />
		<div
			class="flex flex-1 flex-col items-center gap-8 overflow-y-scroll px-2 pb-2"
			@scroll="scroll"
			id="view"
		>
			<div class="w-full max-w-[1208px] flex-1">
				<slot />
			</div>
			<TheFooter class="mt-auto" />
		</div>
		<TheNavbarBottomMobile
			class="flex-shrink-0 self-stretch"
			:border="borderBottom"
		/>
	</div>
</template>

<script setup lang="ts">
import TheFooter from '../components/TheFooter.vue'
import { ref } from 'vue'
import TheNavbarMobile from '../components/TheNavbarMobile.vue'
import TheNavbarBottomMobile from '../components/TheNavbarBottomMobile.vue'
import AlertGroup from '../components/AlertGroup.vue'

const borderTop = ref(false)
const borderBottom = ref(true)

const scroll = (event: Event) => {
	if (!event || !event.target) {
		return
	}

	const scrollTop = (event.target as HTMLElement).scrollTop
	const scrollHeight =
		(event.target as HTMLElement).scrollHeight -
		(event.target as HTMLElement).clientHeight

	if (scrollTop) {
		borderTop.value = true
	} else {
		borderTop.value = false
	}

	if (Math.round(scrollTop) === Math.round(scrollHeight)) {
		borderBottom.value = false
	} else {
		borderBottom.value = true
	}
}
</script>
